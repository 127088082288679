import React from "react";
import { useMatch } from "react-router";
import { wordPressApi } from "app/api/wordpress/wordPressApi";
import Page from "components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ContentWrapper = styled.div`
  text-align: center;
  padding: 50px;
  font-size: 1.2em;
  color: #333;
`;

const FullWidthPage = () => {
	const { t } = useTranslation();
	const match = useMatch("/:locale/*");
	const uri = match?.params["*"] || "";

	// API call for fetching page content
	const { data, isLoading } = wordPressApi.useGetPageContentByUriQuery(uri);

	// Returning a page without a header and footer, displaying only the main content
	return (
		<Page pageType={PageType.PAGE} hideNavigation={true}>
			<SEO
				title={data?.seo.title || "Success"}
				description={data?.seo.metaDesc || "Transaction was successful"}
				keywords={data?.seo.metaKeywords || ""}
				robots={getRobotsContent([
					data?.seo.metaRobotsNofollow,
					'index'
				])}
			/>
			<ContentWrapper>
				{isLoading ? (
					<p>Loading...</p>
				) : (
					<div dangerouslySetInnerHTML={{ __html: data?.content || "No content available." }} />
				)}
			</ContentWrapper>
		</Page>
	);
};

export default FullWidthPage;
